import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/main',
    name: 'Main',
    component: () => import('../views/Main.vue'),
    redirect:'/checkin',
    children:[
      {
        path: '/checkin',
        name: 'CheckIn',
        component: () => import('../views/CheckIn.vue'),
      },
      {
        path: '/boarding',
        name: 'Boarding',
        component: () => import('../views/Boarding.vue'),
      },
      {
        path: '/counter',
        name: 'Counter',
        component: () => import('../views/Map.vue'),
      },
      {
        path: '/find',
        name: 'Find',
        component: () => import('../views/Find.vue'),
      },
      {
        path: '/transportation',
        name: 'Transportation',
        component: () => import('../views/Transportation.vue'),
      },
      {
        path: '/prohibitedGoods',
        name: 'ProhibitedGoods',
        component: () => import('../views/ProhibitedGoods.vue'),
      },
      {
        path: '/announcement',
        name: 'Announcement',
        component: () => import('../views/Announcement.vue'),
      },
      {
        path: '/map',
        name: 'Map',
        component: () => import('../views/Map.vue')
      },
    ]
  },
  
]

const router = new VueRouter({
  routes
})

export default router
