<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
document.oncontextmenu = new Function("event.returnValue=false");
document.addEventListener('contextmenu', function(e) {
  e.preventDefault();
});
</script>
<style lang="scss">
#app {
    font-family: Source Han Sans CN, Source Han Sans CN, Avenir, Helvetica,
        Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // color: #3f3e3d;
}
</style>
<style>
body {
    margin: 0;
    padding: 0;
    --scrollbar-bg: transparent;
    --scrollbar-color: rgba(0, 0, 0, 0.25);
}
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: auto;
    background-color: var(--scrollbar-bg, auto);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 2px auto;
    border: 2px solid var(--scrollbar-bg, auto);
    background: var(--scrollbar-color);
}

::-webkit-scrollbar-track {
    border-radius: 10px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active for <=2.1.8 */ {
  opacity: 0;
}
</style>
