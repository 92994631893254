import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import api from './assets/js/api'

  
Vue.prototype.$api = api

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import VueAwesomeSwiper from 'vue-awesome-swiper';  
import 'swiper/dist/css/swiper.css'; // 确保引入正确的CSS文件路径  

Vue.use(VueAwesomeSwiper);  
