import axios from 'axios'

// axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.baseURL = 'http://hzxsjc-ds.style0101.com/api/xdata'
const http = axios.create();
http.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
http.defaults.headers['sign'] = '1111'
http.defaults.headers['yysign'] = '3333'
http.defaults.timeout = 10000;

export default {
    post(url, params) {
        return new Promise((resolve, reject) => {
            http.post(url, params).then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.data)
                }
            })
        });
    },
    get(url, params) {
        return new Promise((resolve, reject) => {
            http.get(url, {params}).then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res.data)
                }
            })
        });
    }
}